import { MsalProvider } from "@azure/msal-react";
import { Button, ChakraProvider, Flex, Heading } from "@chakra-ui/react";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { msalInstance } from "@/lib/auth";
import { gb } from "@/lib/growthBook";
import { initializeSentry } from "@/lib/sentry";
import { store } from "@/store";
import { theme } from "@/themes";

type AppProviderProps = {
  children: React.ReactNode;
};

initializeSentry();

const ErrorFallback = () => {
  return (
    <Flex
      backgroundColor="#EAEDEB"
      w="100vw"
      alignItems="center"
      flexDir="column"
      padding={16}
    >
      <Heading as="h2" size="3xl" fontWeight="bold" color="red">
        Oops, something went wrong :(
      </Heading>
      <Button
        marginTop={8}
        onClick={() => window.location.assign(window.location.origin)}
        backgroundColor="#FF6600"
        color="white"
        borderRadius={0}
        maxW="10rem"
        _hover={{ bg: "#e93f0e" }}
      >
        Refresh
      </Button>
    </Flex>
  );
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <MsalProvider instance={msalInstance}>
      <GrowthBookProvider growthbook={gb}>
        <ChakraProvider theme={theme}>
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <ReduxProvider store={store}>
              <Router>{children}</Router>
            </ReduxProvider>
          </Sentry.ErrorBoundary>
        </ChakraProvider>
      </GrowthBookProvider>
    </MsalProvider>
  );
};
