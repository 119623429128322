import { Outlet } from "react-router-dom";

import { lazyImport } from "@/utils/lazyImport.ts";

const { Login } = lazyImport(() => import("@/features/auth"), "Login");

const App = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export const publicRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
    ],
  },
];
