import { useIsAuthenticated } from "@azure/msal-react";
import { useRoutes } from "react-router-dom";

import { publicRoutes } from "@/routes/public";

import { protectedRoutes } from "./protected";

export const AppRoutes = () => {
  const isAuthenticated = useIsAuthenticated();

  const routes = isAuthenticated ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};
