import { Badge } from "@chakra-ui/react";
import * as React from "react";

import { useGetAuditCountQuery } from "@/api/auditApi";

type AuditsCountBadgeProps = {
  location?: string;
  startDate?: Date;
  endDate?: Date;
  auditTypeId: string;
  requiredSubmissions: number;
};

export const SubmissionCountBadge = React.memo(
  ({
    location,
    startDate,
    endDate,
    auditTypeId,
    requiredSubmissions,
  }: AuditsCountBadgeProps) => {
    const auditCountQuery = useGetAuditCountQuery(
      {
        auditTypeId,
        location,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
      {
        skip: location === undefined || startDate === undefined,
        refetchOnMountOrArgChange: true,
      },
    );

    return (
      <Badge
        fontSize="1rem"
        colorScheme={
          auditCountQuery.data === undefined
            ? "gray"
            : auditCountQuery.data >= requiredSubmissions
              ? "green"
              : "primary"
        }
      >{`${auditCountQuery.data ?? "-"} / ${requiredSubmissions} Submissions`}</Badge>
    );
  },
);
