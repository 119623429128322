import { Box } from "@chakra-ui/react";

export const Footer = () => {
  return (
    <Box
      w="100vw"
      h="1rem"
      backgroundColor="#343A40"
      zIndex={10}
      marginTop={4}
    />
  );
};
