import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  GridItem,
  Icon,
  Link,
  List,
  ListItem,
} from "@chakra-ui/react";
import { MutableRefObject } from "react";
import { IconType } from "react-icons";
import { GiExitDoor } from "react-icons/gi";

import { User } from "@/features/users";
import { logout } from "@/lib/auth";

type SidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  btnRef: MutableRefObject<any>;
};

export const Sidebar = ({ isOpen, onClose, btnRef }: SidebarProps) => {
  return (
    <Drawer
      placement="right"
      onClose={onClose}
      isOpen={isOpen}
      finalFocusRef={btnRef}
      size="xs"
    >
      <DrawerOverlay />
      <DrawerContent bg="#343A40" color="white">
        <DrawerCloseButton mt={2} _focus={{ outline: "none" }} />
        <DrawerHeader fontSize="1rem" paddingBottom={0}>
          <User />
        </DrawerHeader>
        <DrawerBody paddingX={2}>
          <List>
            <Divider borderColor="gray.200" marginBottom={3} />
            <SidebarItem
              label="Sign Out"
              icon={GiExitDoor}
              handleClick={logout}
            />
          </List>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

type SidebarItemProps = {
  label: string;
  icon?: IconType;
  handleClick?: React.EventHandler<React.MouseEvent>;
};

const SidebarItem = ({ label, icon, handleClick }: SidebarItemProps) => {
  return (
    <ListItem marginLeft="1rem" onClick={handleClick}>
      <Link
        _hover={{
          bg: "gray.400",
          textDecoration: "none",
          color: "white",
        }}
      >
        <Grid
          templateAreas={`"leadingAction leadingVisual label trailingVisual trailingAction"`}
          gridTemplateColumns="min-content min-content minmax(0, auto) min-content min-content"
          gridTemplateRows={"min-content"}
          alignItems="center"
          borderRadius={5}
          _hover={{ bg: "gray.600" }}
          paddingX={3}
        >
          {icon && (
            <GridItem
              marginRight="0.5rem"
              area="leadingVisual"
              boxSizing="border-box"
            >
              <Box w={"fit-content"}>
                <Icon as={icon} />
              </Box>
            </GridItem>
          )}
          <GridItem area="label">{label}</GridItem>
        </Grid>
      </Link>
    </ListItem>
  );
};
