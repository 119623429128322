import { Avatar as ChakraAvatar } from "@chakra-ui/react";
import * as React from "react";

import { useGetAvatarQuery } from "../api/userApi";

type AvatarProps = {
  size?: string;
  width?: string;
  height?: string;
  handleClick?: React.EventHandler<React.MouseEvent>;
  btnRef?: React.MutableRefObject<any>;
};

export const Avatar = ({
  size = "md",
  height,
  width,
  handleClick,
  btnRef,
}: AvatarProps) => {
  const avatar = useGetAvatarQuery();

  return (
    <ChakraAvatar
      as={handleClick ? "button" : undefined}
      src={avatar.data}
      onClick={(e: React.MouseEvent) => (handleClick ? handleClick(e) : null)}
      ref={btnRef}
      border="none"
      size={size}
      height={height}
      width={width}
      _focus={{
        boxShadow: "none",
        outline: "none",
      }}
    />
  );
};
