import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

import { getToken } from "@/lib/auth.tsx";

const baseQuery = fetchBaseQuery({
  baseUrl: "/",
  prepareHeaders: async (headers) => {
    const token = await getToken();

    if (token) {
      headers.set("authorization", `Bearer ${token.accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });

export const apiSlice = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
});
