import { ComponentStyleConfig } from "@chakra-ui/react";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    fontStyle: "bold",
  },
  variants: {
    secondary: {
      bg: "white",
      color: "primary.500",
      borderColor: "primary.500",
      borderWidth: "2px",
      _hover: {
        bg: "primary.50",
      },
    },
    danger: {
      bg: "red.500",
      color: "white",
      _hover: {
        bg: "red.600",
      },
    },
    tertiary: {
      bg: "transparent",
      color: "primary.500",
      textDecoration: "underline",
    },
  },
  defaultProps: {
    size: "lg",
  },
};
