import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";

import { msalConfig, protectedResources } from "@/config/auth";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const logout = () => {
  msalInstance.logoutRedirect();
};

export const getToken = async () => {
  const account = msalInstance.getActiveAccount();

  if (account) {
    const request = {
      scopes: [...protectedResources.apiTextPortal.scopes.send],
      account: account,
    };

    return msalInstance.acquireTokenSilent(request).catch((error) => {
      console.error(error);
      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenRedirect(request);
      } else {
        console.error(error);
      }
    });
  }
};
