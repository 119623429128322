import camelcaseKeys from "camelcase-keys";

import { apiSlice } from "@/lib/rtkquery";
import { ApiResponse } from "@/types";

import { UserDTO } from "../types";

type UserResponse = ApiResponse<UserDTO>;

export const userApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<UserDTO, void>({
      query: () => "api/v1/users/me",
      transformResponse: (response: UserResponse) =>
        camelcaseKeys(response.results, { deep: true }),
    }),
    getAvatar: build.query<string, void>({
      query: () => ({
        url: "api/v1/users/me/photos",
        responseHandler: (response: any) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.blob();
        },
      }),
      transformResponse: (response: Blob | void) => {
        if (response instanceof Blob) {
          const file = new File([response], "avatar.jpg", {
            type: "arrayBuffer",
          });
          return window.URL.createObjectURL(file);
        }
        return "";
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useLazyGetAvatarQuery,
  useGetAvatarQuery,
} = userApi;
