import { extendTheme } from "@chakra-ui/react";

import { Button } from "./components/button";

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        // bg: "#EAEDEB",
        bg: "#f9f9f9",
      },
    },
  },
  colors: {
    primary: {
      50: "#e0edf6",
      100: "#b3d3e8",
      200: "#80b5d9",
      300: "#4d97ca",
      400: "#2681be",
      500: "#006bb3",
      600: "#0063ac",
      700: "#0058a3",
      800: "#004e9a",
      900: "#003c8b",
    },
    brand: "#ff6600",
    darkAccent: "#d4390d",
    dark: "#26202b",
    lightAccent: "#9ea2af",
    light: "#fbfafb",
  },
  components: {
    Button,
  },
});
