import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Spacer,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import * as React from "react";
import { MutableRefObject } from "react";
import { useNavigate } from "react-router-dom";

import darkLogo from "@/assets/logo-white.png";
import { Avatar, useGetAvatarQuery, useGetUserQuery } from "@/features/users";

import { Footer } from "./Footer";
import { Sidebar } from "./Sidebar";

type MainProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainProps) => {
  const [isLargerThan720] = useMediaQuery("(min-width: 720px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  useGetUserQuery();
  useGetAvatarQuery();

  return (
    <>
      <Flex flexDirection="column" minHeight="100vh">
        <Box position="sticky" top={0} zIndex={10}>
          {import.meta.env.VITE_ENVIRONMENT !== "production" && (
            <Flex
              bgColor="red"
              color="white"
              justifyContent="center"
              textAlign="center"
            >
              <Text as="b" decoration="underline">
                This is a sandbox environment! Anything submitted will not count
                towards successful audits.
              </Text>
            </Flex>
          )}
          {isLargerThan720 ? (
            <DesktopLayout onOpen={onOpen} btnRef={btnRef} />
          ) : (
            <MobileLayout onOpen={onOpen} btnRef={btnRef} />
          )}
        </Box>
        <Sidebar isOpen={isOpen} onClose={onClose} btnRef={btnRef} />

        <Flex flexGrow="1" justifyContent="center">
          <Box
            marginTop={5}
            marginX={3}
            width={isLargerThan720 ? "6xl" : "100vw"}
          >
            {children}
          </Box>
        </Flex>
        {isLargerThan720 && <Footer />}
      </Flex>
    </>
  );
};

type NavbarProps = {
  onOpen: () => void;
  btnRef: MutableRefObject<any>;
};

const MobileLayout = ({ onOpen, btnRef }: NavbarProps) => {
  return (
    <Box w="100vw" backgroundColor="#343A40" padding={2}>
      <Box position="relative" h="2rem" textAlign="center">
        <Box
          maxW="125px"
          position="absolute"
          top="40%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Image src={darkLogo} w={"100%"} alt={"Monarch logo"} />
        </Box>
        <IconButton
          aria-label="Show sidebar"
          icon={<HamburgerIcon />}
          bg="transparent"
          color="white"
          fontSize="2rem"
          float="right"
          ref={btnRef}
          _active={{
            bg: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          onClick={onOpen}
        ></IconButton>
      </Box>
    </Box>
  );
};

const DesktopLayout = ({ onOpen, btnRef }: NavbarProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Box w="100%" maxHeight={"5rem"} backgroundColor={"#343A40"}>
        <Flex
          alignItems={"flex-end"}
          justifyContent={"space-around"}
          maxW={"6xl"}
          margin={"0 auto"}
          p={4}
        >
          <Box
            maxW={"150px"}
            m={1}
            onClick={() => navigate("/")}
            cursor="pointer"
          >
            <Image src={darkLogo} w={"100%"} alt={"Monarch logo"} />
          </Box>
          <Spacer />
          <Heading as="h1" size="xl" fontWeight={"bold"} color={"white"}>
            Site Audits
          </Heading>
          <Box w="fit-content" m={1.5} ml={6}>
            <Avatar
              width="3rem"
              height="3rem"
              handleClick={onOpen}
              btnRef={btnRef}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};
