import { Box, Flex, Text } from "@chakra-ui/react";

import { useGetUserQuery } from "../api/userApi.ts";
import { Avatar } from "./Avatar";

export const User = () => {
  const user = useGetUserQuery();

  return (
    <>
      <Flex>
        <Avatar />
        <Box ml={5}>
          {user.data && (
            <>
              <Text as="b">{user.data?.displayName}</Text>
              <Text fontSize="xs">{user.data?.userPrincipalName}</Text>
            </>
          )}
        </Box>
      </Flex>
    </>
  );
};
