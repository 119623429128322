import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    authority: import.meta.env.VITE_AUTH_AUTHORITY,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  system: {
    allowNativeBroker: false,
  },
};

export const protectedResources = {
  apiTextPortal: {
    endpoint: "http://localhost:8000/api/v1",
    scopes: {
      send: [import.meta.env.VITE_API_SEND_SCOPE],
    },
  },
};

export const loginRequest: RedirectRequest = {
  scopes: [...protectedResources.apiTextPortal.scopes.send],
};
